import { FC } from "react";

const FbIcon: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
        >
            <g clipPath="url(#clip0_122_35128)">
                <path
                    d="M23.625 12.8154C23.625 6.39355 18.4219 1.19043 12 1.19043C5.57812 1.19043 0.375 6.39355 0.375 12.8154C0.375 18.6176 4.62609 23.427 10.1836 24.2998V16.1759H7.23047V12.8154H10.1836V10.2542C10.1836 7.3409 11.918 5.73168 14.5744 5.73168C15.8466 5.73168 17.1769 5.95855 17.1769 5.95855V8.81793H15.7106C14.2669 8.81793 13.8164 9.71418 13.8164 10.6334V12.8154H17.0405L16.5248 16.1759H13.8164V24.2998C19.3739 23.427 23.625 18.6176 23.625 12.8154Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_122_35128">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.81543)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FbIcon