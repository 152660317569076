import React, { ReactNode, forwardRef, useContext } from "react";
import { getHelperText, getValue, regexValue} from "./helpers"
import FormContext from "./FormContext";

export interface IGPTextField {
    errorText?: string;
    id: string;
    onChange?: Function;
    regex?: string;
    helperText?: string;
    value?: string;
    label?: string;
    type?: string;
    startAdornment?: ReactNode;
    placeholder?: string;
    fullWidth?: true;
    className?: any;
}

const GPTextField = forwardRef<any, IGPTextField>((props, ref) => {
    const {
        errorText,
        fullWidth,
        helperText,
        id,
        label,
        onChange,
        regex,
        startAdornment,
        className,
        placeholder,
        type,
        value,
    } = props;

    const context = useContext(FormContext);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = regexValue(id, context, regex, event.target.value);

        if (!!onChange) {
            onChange({ id, value: val }, context);
        } else {
            context?.onChange({ id, value: val });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <div {...(className ? { className } : {})}>
            <label className="block text-lg tracking-tighter font-medium leading-6">
                {label}
            </label>
            <div
                className={`relative rounded shadow-sm inline-flex items-center mt-0 ${
                    fullWidth ? "w-full" : "w-52"
                }`}
            >
                {startAdornment && startAdornment}
                <input
                    type={type}
                    name={id}
                    id={id}
                    className={`${
                        startAdornment ? "pl-10 " : "pl-2"
                    } block w-full h-12 rounded border-0 py-1.5 pr-4  text-Gray-70 placeholder:text-Gray-40 text-base leading-6 focus:outline-none ${
                        isError && `ring-red-700`
                    }`}
                    placeholder={placeholder}
                    onChange={onChangeValue}
                    ref={ref}
                    value={getValue(id, context, value)}
                />
            </div>
            {/* todo helper text color*/}
            {inputHelperText && (
                <span className="text-white h-5 w-full block text-sm">
                    {inputHelperText}
                </span>
            )}
            <span className="text-red-700 h-5 w-full block text-sm">
                {isError && errorText}
            </span>
        </div>
    );
});

GPTextField.defaultProps = {
    errorText: "",
    helperText: "",
};

export default GPTextField;
