import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { FC, useMemo } from "react";
const containerStyle = {
    width: "100%",
    height: "auto",
};
interface IMap {
    lat?: number;
    lng?: number;
    zoom?: number;
}

const MapComponent: FC<IMap> = ({ lat, lng, zoom }) => {
    const center = useMemo(() => {
        if (lat && lng) return { lat, lng };
        else return { lat: 59.911491, lng: 10.757933 };
    }, [lat, lng]);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    });
    // const [map, setMap] = useState(null);

    // const onLoad = useCallback(function callback(map: any) {
    //     // map.fitBounds(bounds);
    //     setMap(map);
    // }, []);

    // const onUnmount = useCallback(function callback(map: any) {
    //     setMap(null);
    // }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom ? zoom : 10}
            // onLoad={onLoad}
            // onUnmount={onUnmount}
        >
            <Marker position={center} />
        </GoogleMap>
    ) : (
        <></>
    );
};

export default MapComponent;
