import { ReactNode, useContext } from "react";
import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers"
import { Select, SelectStylesType } from "@material-tailwind/react";
interface IGPSelect extends Omit<SelectStylesType, "onChange"> {
    errorText?: string;
    formControlProps?: object;
    helperText?: string;
    id: string;
    labelProps?: object;
    multiple?: boolean;
    onChange?: Function;
    children: ReactNode;
    value?: string|number;
    className?: string;
    fullWidth?: boolean;
    startAdornment?: ReactNode;
}

function GPSelect(props: IGPSelect) {
    const {
        children,
        errorText,
        helperText,
        id,
        onChange,
        value,
        className,
        startAdornment,
        fullWidth,
    } = props;

    const context = useContext(FormContext);

    const onChangeValue = (event: any) => {
        if (!!onChange) {
            onChange({ id, value: event }, context);
        } else {
            context?.onChange({ id, value: event });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <div {...(className ? { className } : {})}>
            <div
                className={`relative rounded shadow-sm inline-flex items-center h-12 mt-0 ${
                    fullWidth ? "w-full" : "w-auto"
                }`}
            >
                {startAdornment && (
                    <div className="absolute z-[98] ml-2 top-1/4">
                        {startAdornment}
                    </div>
                )}
                <Select
                    value={getValue(id, context, value?.toString())}
                    onChange={onChangeValue}
                    className={`${
                        startAdornment ? "pl-10 " : "pl-2"
                    } custom-select flex relative w-full bg-white h-12 text-base rounded border-0 py-1.5 pr-4 before::border-t-0  text-Gray-70  after::border-0 sm:text-base focus:ring-0 leading-6 outline-none focus:outline-none ring-0`}
                    labelProps={{
                        style: {
                            display: "none",
                        },
                    }}
                    error={isError}
                    arrow={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M12 14.9758C11.8667 14.9758 11.7374 14.9508 11.612 14.9008C11.4867 14.8508 11.3827 14.7841 11.3 14.7008L6.70005 10.1008C6.51672 9.91745 6.42505 9.68411 6.42505 9.40078C6.42505 9.11745 6.51672 8.88411 6.70005 8.70078C6.88338 8.51745 7.11672 8.42578 7.40005 8.42578C7.68338 8.42578 7.91672 8.51745 8.10005 8.70078L12 12.6008L15.9 8.70078C16.0834 8.51745 16.3167 8.42578 16.6 8.42578C16.8834 8.42578 17.1167 8.51745 17.3 8.70078C17.4834 8.88411 17.575 9.11745 17.575 9.40078C17.575 9.68411 17.4834 9.91745 17.3 10.1008L12.7 14.7008C12.6 14.8008 12.4917 14.8718 12.375 14.9138C12.2584 14.9558 12.1334 14.9764 12 14.9758Z"
                                fill="#323947"
                            />
                        </svg>
                    }
                >
                    {children}
                </Select>
            </div>
            {inputHelperText?.length > 0 && (
                <span className="text-sm text-white">{inputHelperText}</span>
            )}
        </div>
    );
}

export default GPSelect;
