import { DialogBody, DialogBodyProps } from "@material-tailwind/react";
import { forwardRef } from "react";

interface IGPModalContent extends DialogBodyProps {}

const GPModalContent = forwardRef<HTMLDivElement, IGPModalContent>(
    (props, ref) => {
        const { children, ...restProps } = props;

        return (
            <DialogBody {...restProps} ref={ref}>
                {children}
            </DialogBody>
        );
    }
);

export default GPModalContent;
