import { Checkbox, Typography } from "@material-tailwind/react";
import React, { useContext } from "react";

import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers"

// interface IGPCheckbox extends Omit<CheckboxProps, "onChange" | "value"> {
//     checked?: boolean;
//     errorText?: string;
//     formControlProps?: {};
//     formControlLabelProps?: any;
//     helperText?: string;
//     label: string;
//     id: string;
//     value?: boolean | null;
//     onChange?: Function;
// }

function GPCheckbox({
    id,
    label,
    onChange,
    checked,
    errorText,
    formControlLabelProps,
    formControlProps,
    helperText,
    value,
    ...restProps
}: any) {
    const context = useContext(FormContext);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = value !== undefined ? value : event.target.checked;

        if (!!onChange) {
            onChange(
                {
                    id,
                    value: val,
                    checkboxValue: event.target.value,
                    event: event,
                },
                context
            );
        } else {
            context?.onChange({
                id,
                value: event.target.checked,
                checkboxValue: event.target.value,
            });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <>
            <Checkbox
                {...restProps}
                checked={Boolean(getValue(id, context, checked))}
                onChange={onChangeValue}
                color={isError ? "red" : ""}
                className="rounded checked:bg-white border-Gray-60 checked:border-Gray-60 border-2"
                label={
                    <Typography
                        variant="small"
                        className="flex items-center font-normal"
                        {...formControlLabelProps}
                    >
                        {label}
                    </Typography>
                }
                iconProps={{className: "text-Gray-60"}}
         
            />
            {inputHelperText?.length > 0 && (
                <Typography>{inputHelperText}</Typography>
            )}
        </>
    );
}

export default GPCheckbox;
