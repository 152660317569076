import { FC } from "react";

const HamburgerIcon: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
        >
            <path
                d="M8.33301 28.3337H31.6663M8.33301 20.0003H31.6663M8.33301 11.667H31.6663"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default HamburgerIcon;
