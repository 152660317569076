import { FC } from "react";
import { Modal, ModalContent, ModalTitle } from "ui";
import { modalHide } from "app/App/actions";
import { useDispatch } from "react-redux";

interface IImageModal {
    imageSrc: string;
}
const ImageModal: FC<IImageModal> = ({ imageSrc }) => {
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(modalHide("ImageModal"));
    };

    return (
        <>
            <Modal size="lg" open handler={() => {}}>
                <ModalTitle
                    buttonColor="black"
                    onClose={handleCloseModal}
                    className="text-lg font-medium leading-6 text-black md:text pr-6 pl-8 py-2"
                />

                <ModalContent className="p-0">
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt*/}
                    <img
                        alt="image-gallery"
                        className="h-auto w-full object-contain object-center"
                        src={imageSrc}
                    />
                </ModalContent>
            </Modal>
        </>
    );
};

export default ImageModal;
