import { FC } from "react";
import { IIcon } from "ui/types";

const SearchIcon: FC<IIcon> = ({ color }) => {
    const className = color ? `stroke-Gray-80` : "stroke-white md:stroke-Gray-80"
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            className={className}
        >
            <path
                d="M29.9996 29.9996L23.9246 23.9246M23.9246 23.9246C24.8996 22.9495 25.6731 21.7919 26.2008 20.5179C26.7285 19.244 27.0001 17.8785 27.0001 16.4996C27.0001 15.1206 26.7285 13.7552 26.2008 12.4812C25.6731 11.2072 24.8996 10.0496 23.9246 9.07456C22.9495 8.09949 21.7919 7.32603 20.5179 6.79833C19.244 6.27063 17.8785 5.99902 16.4996 5.99902C15.1206 5.99902 13.7552 6.27063 12.4812 6.79833C11.2072 7.32603 10.0496 8.09949 9.07456 9.07456C7.10533 11.0438 5.99902 13.7146 5.99902 16.4996C5.99902 19.2845 7.10533 21.9553 9.07456 23.9246C11.0438 25.8938 13.7146 27.0001 16.4996 27.0001C19.2845 27.0001 21.9553 25.8938 23.9246 23.9246Z"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SearchIcon;
