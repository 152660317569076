import { merge } from "lodash";
import { useSelector } from "react-redux";
import SearchModal from "app/App/modals/SearchModal";

interface IModal {
    [key: string]: any;
}

let data: IModal = {
    SearchModal,
};

export function registerModals(modals: any) {
    data = merge(data, modals);
}

function Modals() {
    const modals = useSelector((state: any) => state.app.modals);

    const modalsToShow = [];

    for (let idx in modals) {
        const modal: IModal = modals[idx];

        if (data[modal.id] !== undefined) {
            let View = data[modal.id];

            modalsToShow.push(
                <View key={modal.id} id={modal.id} idx={idx} {...modal.props} />
            );
        }
    }

    return <>{modalsToShow}</>;
}

export default Modals;
