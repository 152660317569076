import FilterModal from "./modals/FilterModal";
import ImageModal from "./modals/ImageModal";
import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import { configType, permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const url = "/portal";

const getConfig = (permissions: permissionsType) => {
    const config: configType = {
        permissions: {
            read: true,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    routing.push({
        path: url,
        element: (
            <AsyncComponent path="Restaurants/pages/RestaurantsIndexPage" />
        ),
    });
    routing.push({
        path: `${url}/:id`,
        element: (
            <AsyncComponent path="Restaurants/pages/RestaurantPage" />
        ),
    });
    registerModals({
        FilterModal,
        ImageModal,
    })
    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
