import { FC, useEffect, useRef, useState } from "react";
import { Checkbox, Form } from "ui";
import { IFilterForm } from "../interfaces";
import { IGPFormRef } from "ui/Form/Form";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
} from "@material-tailwind/react";
import { cuisineData, drinkData, retterData } from "../data";

function Icon({ id, open }: { id: number; open: number | string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${
                id === open ? "rotate-180" : ""
            } h-5 w-5 transition-transform`}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
        </svg>
    );
}
const FilterForm: FC<IFilterForm> = ({
    data,
    errors,
    loading,
    onSubmit,
    refresh,
    submitForm,
}) => {
    const [open, setOpen] = useState<number[]>([0]);
    const handleOpenAccordion = (value: number) => {
        if (open.includes(value)) {
            setOpen(open.filter((idx) => idx !== value));
        } else {
            setOpen([...open, value]);
        }
    };

    const formRef = useRef<IGPFormRef | null>();

    useEffect(() => {
        if (submitForm) {
            handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitForm]);
    useEffect(() => {
        if (formRef.current) {
            formRef.current.refreshFormData(data);
        }
    }, [data]);

    const handleSubmit = () => {
        if (formRef.current !== null) {
            const data: any = formRef?.current?.getData();
            onSubmit(data);
        }
    };

    return (
        <Form
            ref={formRef}
            data={data}
            errors={errors}
            fields={{
                cuisine: { default: [] },
                drinks: { default: [] },
                retter: { default: [] },
            }}
            loading={loading}
            onSubmit={() => {}}
            preventLeftForm
            unsaved={false}
        >
            <div className="flex flex-col gap-2">
                {[
                    { label: "Cuisine", values: cuisineData },
                    { label: "Retter", values: retterData },
                    { label: "Drinks", values: drinkData },
                ].map((item: any, idx: number) => {
                    return (
                        <Accordion
                            key={`accordion-${item.label}`}
                            open={open.includes(idx)}
                            icon={
                                <Icon
                                    id={idx}
                                    open={open.includes(idx) ? idx : ""}
                                />
                            }
                        >
                            <AccordionHeader
                                onClick={() => handleOpenAccordion(idx)}
                                className="border-b-0 text-Gray-80 font-dmSans py-2 mb-4 px-4 text-2xl font-bold justify-start gap-1/4"
                            >
                                {item.label}
                            </AccordionHeader>
                            <AccordionBody className="font-inter px-1 py-0 text-Gray-60 text-base grid grid-cols-1 sm:grid sm:grid-cols-2 sm:gap-x-4">
                                {item.values.map((val: any, idx: number) => {
                                    return (
                                        <Checkbox
                                            className="text-Gray-60 h-auto"
                                            id={`${item.label.toLowerCase()}.${idx}`}
                                            key={`${item.label.toLowerCase()}.${idx}`}
                                            label={val.name}
                                        />
                                    );
                                })}
                            </AccordionBody>
                        </Accordion>
                    );
                })}
            </div>
        </Form>
    );
};
export default FilterForm;
