import { APP_INIT, APP_LOADED } from "types";
import { loadModules } from "moduleSaga";
import { call, put, takeLatest } from "@redux-saga/core/effects";

function* initApp() {
    yield call(loadModules);

    yield put({
        type: APP_LOADED,
    });
}

export const appSagas = [takeLatest(APP_INIT, initApp)];
