import { modalHide } from "app/App/actions";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalContent, ModalTitle } from "ui";
import SearchForm from "../forms/SearchForm";
import PageWrapper from "../components/PageWrapper";

const SearchModal: FC = () => {
    const dispatch = useDispatch();
    const handleCloseModal = () => {
        dispatch(modalHide("SearchModal"));
    };
    const handleSubmit = (data: any) => {
        console.log("submit", data);
        dispatch(modalHide("SearchModal"));
    };
    return (
        <>
            <Modal
                open
                size="xxl"
                handler={() => {}}
                className="bg-Tearl-70 text-white"
            >
                <ModalTitle
                    onClose={handleCloseModal}
                    className="text-lg font-medium leading-6 text-white flex justify-end p-6 w-full"
                />
                <ModalContent className="mt-9 p-4 sm:px-36 sm:py-32 xl:p-32">
                    <PageWrapper>
                        <SearchForm
                            data={{} as any}
                            loading={false}
                            onSubmit={handleSubmit}
                        />
                    </PageWrapper>
                </ModalContent>
            </Modal>
        </>
    );
};

export default SearchModal;
