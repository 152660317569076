import { DialogHeader, DialogHeaderProps } from "@material-tailwind/react";

import React, { forwardRef } from "react";
import CloseIcon from "ui/Icons/CloseIcon";

interface IGPModalTitle extends Omit<DialogHeaderProps, "children"> {
    onClose: (event: React.MouseEvent) => void;
    children?: React.ReactNode;
    buttonColor?: string;
}

const GPModalTitle = forwardRef<HTMLDivElement, IGPModalTitle>((props, ref) => {
    const { children, buttonColor, onClose, ...restProps } = props;

    return (
        <DialogHeader {...restProps} ref={ref}>
            <div className="flex flex-row justify-between items-center w-full">
                <p className="">{children}</p>

                {!!onClose && (
                    <button onClick={onClose}>
                        <CloseIcon color={buttonColor} />
                    </button>
                )}
            </div>
        </DialogHeader>
    );
});

export default GPModalTitle;
