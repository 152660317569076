import { FC, ReactNode } from "react";

interface IPageWrapper {
    children: ReactNode;
    className?: string;
}
const PageWrapper: FC<IPageWrapper> = ({ children, className }) => {
    return (
        <div
            className={`max-w-[1248px] m-auto w-full ${
                className ? className : ""
            }`}
        >
            {children}
        </div>
    );
};

export default PageWrapper;
