import { request } from "api/actions";
import {
    APP_HANDLE_ERROR,
    APP_SETTINGS_REQUEST,
    APP_SETTINGS_FAILED,
    APP_SETTINGS_SUCCESS,
    CONFIRMATION_CONFIRMED,
    CONFIRMATION_UNCONFIRMED,
    MODAL_HIDE,
    MODAL_SHOW,
    SNACKBAR_HIDE,
    SNACKBAR_SHOW,
    SIDE_NAV_TYPE,
} from "types";

export const confirmationConfirmed = () => ({
    type: CONFIRMATION_CONFIRMED,
});
export const confirmationUnconfirmed = () => ({
    type: CONFIRMATION_UNCONFIRMED,
});

export const handleError = (error: any) => ({
    type: APP_HANDLE_ERROR,
    error,
});

export const modalHide = (id: string, props?: any) => ({
    type: MODAL_HIDE,
    id,
    props,
});
export const modalShow = (id: string, props?: any) => ({
    type: MODAL_SHOW,
    id,
    props,
});

export const snackbarHide = () => ({
    type: SNACKBAR_HIDE,
});

export const snackbarShow = (data: any, closeAfter = 6000, type = "alert") => {
    return {
        type: SNACKBAR_SHOW,
        data: {
            data,
            closeAfter,
            type,
        },
    };
};

export const changeSideNavType = (type: string) => ({
    type: SIDE_NAV_TYPE,
    data: type,
});

export const appSettings = () =>
    request(APP_SETTINGS_REQUEST, APP_SETTINGS_FAILED, APP_SETTINGS_SUCCESS);
