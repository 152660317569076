import { modalHide } from "app/App/actions";
import { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RefreshIcon,
} from "ui";
import FilterForm from "../forms/FilterForm";
import AppContext, { IAppContext } from "app/App/context";

const FilterModal: FC = () => {
    const context = useContext(AppContext) as IAppContext;
    const [refresh, setRefresh] = useState<number>(0);

    const dispatch = useDispatch();
    const [submitForm, setSubmitForm] = useState(false);
    const handleCloseModal = () => {
        dispatch(modalHide("FilterModal"));
    };
    const handleRefresh = () => {
        setRefresh((prev) => prev + 1);
    };
    const handleSubmit = (formData: any) => {
        console.log("submit", formData);
        handleCloseModal();
    };
    return (
        <>
            <Modal
                open
                size={context.isMobile ? "xxl" : "md"}
                handler={() => {}}
                className="bg-white text-Gray-80 relative sm:rounded-md "
            >
                <ModalTitle
                    buttonColor="black"
                    onClose={handleCloseModal}
                    className="text-lg font-medium leading-6 text-black md:text pr-6 pl-8 py-2"
                >
                    <div
                        onClick={handleRefresh}
                        className="cursor-pointer sm:hidden flex flex-row gap-2 items-center justify-center text-Gray-80 font-medium"
                    >
                        <RefreshIcon /> Nullstill
                    </div>
                </ModalTitle>

                <ModalContent className="py-2 px-8 overflow-y-auto flex-1 flex flex-col sm:min-h-[450px]">
                    <FilterForm
                        refresh={refresh}
                        data={{} as any}
                        loading={false}
                        onSubmit={handleSubmit}
                        submitForm={submitForm}
                    />
                </ModalContent>
                <ModalActions className="sm:rounded-b-md flex flex-col w-full justify-center items-center sm:justify-between sm:flex-nowrap sm:flex-row sm:bg-Orange-10 px-4 sm:px-8 py-4 sm:py-6">
                    <div
                        onClick={handleRefresh}
                        className=" cursor-pointer hidden sm:flex flex-row gap-2 items-center justify-center text-Gray-80 font-medium"
                    >
                        <RefreshIcon /> Nullstill
                    </div>
                    <div className="w-full flex flex-col sm:flex-row-reverse justify-center sm:justify-start items-center gap-2">
                        <Button
                            variant="filled"
                            className="w-full text-lg sm:w-36 font-bold rounded-4xl text-white bg-Orange-50 hover:bg-Orange-50-darker ease-in-out duration-300 items-center justify-center py-2 px-14"
                            onClick={() => setSubmitForm(true)}
                        >
                            Bruk
                        </Button>
                        <Button
                            variant="text"
                            onClick={handleCloseModal}
                            className="w-full text-lg sm:w-36 font-bold rounded-4xl py-2 px-14 ease-in-out duration-300"
                        >
                            Lukk
                        </Button>
                    </div>
                </ModalActions>
            </Modal>
        </>
    );
};

export default FilterModal;
