import { DialogFooter, DialogFooterProps } from "@material-tailwind/react";
import { forwardRef } from "react";

interface IGPModalActions extends DialogFooterProps {}

const GPModalActions = forwardRef<HTMLDivElement, IGPModalActions>(
    (props, ref) => {
        const { children, ...restProps } = props;

        return (
            <DialogFooter {...restProps} ref={ref}>
                {children}
            </DialogFooter>
        );
    }
);

export default GPModalActions;
