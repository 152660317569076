import { FC, useRef } from "react";
import {
    CalendarIcon,
    Form,
    InputText,
    LocationIcon,
    ProfileIcon,
    Select,
    TimeIcon,
} from "ui";
import { ISearchForm } from "../interfaces";
import { IGPFormRef } from "ui/Form/Form";
import { Option } from "@material-tailwind/react";
import { modalHide } from "../actions";
import { useDispatch } from "react-redux";

const SearchForm: FC<ISearchForm> = ({ data, errors, loading, onSubmit }) => {
    const formRef = useRef<IGPFormRef | null>();
    const dispatch = useDispatch();

    const handleSubmit = () => {
        if (formRef.current !== null) {
            const data: any = formRef?.current?.getData();
            onSubmit(data);
        }
    };

    return (
        <div className="bg-Tearl-70">
            <div>
                <Form
                    ref={formRef}
                    data={data}
                    errors={errors}
                    fields={{
                        date: { default: 1 },
                        time: {},
                        people: {},
                        location: {},
                        search: {},
                    }}
                    loading={loading}
                    onSubmit={() => {}}
                    preventLeftForm
                    unsaved={false}
                >
                    <div className="grid grid-rows-3 gap-x-1 gap-y-6 sm:gap-y-4  grid-cols-2 sm:grid-rows-2 sm:grid-cols-3 xl:flex xl:flex-row xl:flex-wrap">
                        <Select
                            id="date"
                            className="col-span-1 h-12 xl:w-44"
                            fullWidth
                            startAdornment={<CalendarIcon />}
                        >
                            {[1, 2].map((item: any) => {
                                return (
                                    <Option
                                        value={item.toString()}
                                        key={`select-date-${item}`}
                                    >
                                        {item}
                                    </Option>
                                );
                            })}
                        </Select>

                        <Select
                            id="time"
                            className="col-span-1 h-12 xl:w-44"
                            fullWidth
                            startAdornment={<TimeIcon />}
                        >
                            {[1, 2].map((item: any, idx: number) => {
                                return (
                                    <Option
                                        value={item}
                                        key={`select-time-${item}`}
                                    >
                                        {item}
                                    </Option>
                                );
                            })}
                        </Select>

                        <Select
                            id="people"
                            className="col-span-1 h-12 xl:w-44"
                            fullWidth
                            startAdornment={<ProfileIcon />}
                        >
                            {[1, 2].map((item: any, idx: number) => {
                                return (
                                    <Option
                                        value={item}
                                        key={`select-people-${item}`}
                                    >
                                        {item}
                                    </Option>
                                );
                            })}
                        </Select>

                        <Select
                            id="location"
                            className="col-span-1 h-12 xl:w-44"
                            fullWidth
                            startAdornment={<LocationIcon />}
                        >
                            {[1, 2].map((item: any, idx: number) => {
                                return (
                                    <Option
                                        value={item}
                                        key={`select-location-${item}`}
                                    >
                                        {item}
                                    </Option>
                                );
                            })}
                        </Select>
                        <InputText
                            id="search"
                            placeholder="Kjøkken eller restaurant navn"
                            fullWidth
                            className="col-span-2 xl:col-span-1 h-12 xl:w-44 xl:flex-1 xl:ml-4"
                            startAdornment={
                                <span className="h-6 absolute top-1/4 left-0 ml-2">
                                    {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M16.875 16.875L21.375 21.375"
                                            stroke="#323947"
                                            strokeWidth="2.25"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M11.25 18.375C15.185 18.375 18.375 15.185 18.375 11.25C18.375 7.31497 15.185 4.125 11.25 4.125C7.31497 4.125 4.125 7.31497 4.125 11.25C4.125 15.185 7.31497 18.375 11.25 18.375Z"
                                            stroke="#323947"
                                            strokeWidth="2.25"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            }
                        />
                    </div>
                </Form>
            </div>
            <div className="w full flex flex-col sm:flex-row-reverse justify-center gap-9 mt-20">
                <button
                    className="text-lg sm:w-36 font-bold rounded-4xl text-white bg-Orange-50 hover:bg-Orange-50-darker active:bg-Orange-50-dark ease-in-out duration-300 items-center justify-center py-2 px-14"
                    onClick={handleSubmit}
                >
                    Søk
                </button>
                <button onClick={() =>  dispatch(modalHide("SearchModal"))} className="text-white sm:text-black text-lg sm:w-36 font-bold rounded-4xl bg-Tearl-60 hover:bg-Tearl-60-darker active:bg-Tearl-60-dark sm:bg-Gray-35 sm:hover:bg-Gray-35-darker sm:active:bg-Gray-35-dark  ease-in-out duration-300 items-center justify-center py-2 px-14">
                    Lukk
                </button>
            </div>
        </div>
    );
};
export default SearchForm;
