import { FC } from "react";

interface ICloseIcon {
    color?: string;
}
const CloseIcon: FC<ICloseIcon> = ({color}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
        >
            <path
                d="M25.875 25.875L10.125 10.125M25.875 10.125L10.125 25.875"
                stroke={color ? color : "white"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CloseIcon;
