import DeliImage from "assets/deli.jpg";
import KarlsborgImage from "assets/karlsborg.jpg";
import GrillerietImage from "assets/grilleriet.jpeg";
import G1 from "assets/gallery/G1.png";
import G2 from "assets/gallery/G2.png";
import G3 from "assets/gallery/G3.png";
import G4 from "assets/gallery/G4.png";

export const cuisineData = [
    { id: 1, name: "Europeisk" },
    { id: 2, name: "Skandinavisk" },
    { id: 3, name: "Norsk" },
];
export const retterData = [
    { id: 1, name: "Afternoon Tea" },
    { id: 2, name: "Østers" },
    { id: 3, name: "Salat" },
    { id: 4, name: "Burger" },
    { id: 5, name: "Smørbrød" },
    { id: 6, name: "Fisk" },
    { id: 7, name: "Skalldyr" },
    { id: 8, name: "Entrecôte" },
    { id: 9, name: "Bouillabaisse" },
    { id: 10, name: "Andeconfit" },
];
export const drinkData = [
    { id: 1, name: "Champagne" },
    { id: 2, name: "cocktails" },
    { id: 3, name: "vin" },
];

export const data: any = [
    // {
    //     id: 1,
    //     name: "Palmen Restaurant",
    //     address_1: "Karl Johans gt. 31, 0159 Oslo",
    //     cuisine: [1, 2, 3],
    //     retter: [1, 2, 3],
    //     drinks: [1, 2, 3],
    //     short_description:
    //         "I Palmen serverer vi egne lunsj- og middagsmenyer og vår populære Afternoon Tea i helgene.",
    //     description:
    //         "I Palmen serverer vi egne lunsj- og middagsmenyer og vår populære Afternoon Tea i helgene. Våre servitører står klare til å gi deg en utsøkt opplevelse. Enten du kommer alene, sammen med familie eller venner, eller kanskje med en utenlandsk forretningsforbindelse, vil kjøkkenet og våre servitører stå klare til å tilby en kulinarisk opplevelse i Palmens vakre omgivelser. Vi serverer både lunsj og middag. I helgene kan du nyte vår populære Afternoon Tea, som nylig ble kåret til Oslos beste av Vink Aftenposten.",
    //     availability: ["12:00", "12:30", "13:30", "19:30", "19:45", "20:00"],
    //     image: R1,
    //     phone: "+47 23 21 20 00",
    //     menu: "https://www.google.com/",
    //     website: "https://www.google.com/",
    //     city: "Oslo",
    //     alcohol_serving: true,
    //     open_hours: [
    //         { label: "Mandag til fredag", value: "11:00 - 22:00" },
    //         { label: "Lørdag ", value: "12.00–22.00" },
    //     ],
    //     links: {
    //         online_booking: "https://www.google.com/",
    //     },
    //     gallery: [G1, G2, G3, G4],
    //     lat: 59.911491,
    //     lng: 10.757933,
    // },
    {
        id: 2,
        name: "Karlsborg Spiseforretning",
        address_1: "Kongsveien 21, 0193 Oslo",
        cuisine: [1, 2, 3],
        retter: [1, 2, 3],
        drinks: [1, 2, 3],
        short_description:
            "Afternoon Tea på lørdager har blitt en institusjon hos oss oppe på heia. Vi anbefaler å bestille bord for å få en best mulig opplevelse",
        description:
            "I Palmen serverer vi egne lunsj- og middagsmenyer og vår populære Afternoon Tea i helgene.",
        availability: [
            "12:00",
            "12:30",
            "13:30",
            "19:30",
            "19:45",
            "20:00",
            "20:30",
        ],
        image: KarlsborgImage,
        phone: "+47 23 21 20 00",
        menu: "https://www.google.com/",
        website: "https://www.google.com/",
        city: "Oslo",
        alcohol_serving: true,
        open_hours: [
            { label: "Mandag til fredag", value: "11:00 - 22:00" },
            { label: "Lørdag ", value: "12.00–22.00" },
        ],
        links: {
            online_booking: "https://www.google.com/",
        },
        gallery: [G1, G2, G3, G4],
        lat: 59.911491,
        lng: 10.757933,
    },
    {
        id: 3,
        name: "DinDeli",
        address_1: "Kristian Augusts gate 15-17, 0164 Oslo",
        cuisine: [1, 3],
        retter: [1, 2],
        drinks: [1, 2],
        short_description:
            "Italiensk kafé, restaurant og delikatessebutikk. Smak på kaffe, foccacia, dagens risotto, og såklart en liten søtsak.",
        description:
            "I Palmen serverer vi egne lunsj- og middagsmenyer og vår populære Afternoon Tea i helgene.",
        availability: ["12:00", "12:30", "13:30", "19:30"],
        image: DeliImage,
        phone: "+47 23 21 20 00",
        menu: "https://www.google.com/",
        website: "https://www.google.com/",
        city: "Oslo",
        alcohol_serving: true,
        open_hours: [
            { label: "Mandag til fredag", value: "11:00 - 22:00" },
            { label: "Lørdag ", value: "12.00–22.00" },
        ],
        links: {
            online_booking: "https://www.google.com/",
        },
        gallery: [G1, G2, G3, G4],
        lat: 59.911491,
        lng: 10.757933,
    },
    {
        id: 4,
        name: "Grilleriet",
        address_1: "Storgata 21-23, 0184 Oslo",
        cuisine: [2, 3],
        retter: [1, 2],
        drinks: [1, 2],
        short_description:
            "Grilleriets unike karakter skapes av kullgrillen som er selve hjertet av restauranten.",
        description:
            "I Palmen serverer vi egne lunsj- og middagsmenyer og vår populære Afternoon Tea i helgene.",
        availability: ["12:00", "12:30", "13:30", "19:30", "19:45", "20:30"],
        image: GrillerietImage,
        phone: "+47 23 21 20 00",
        menu: "https://www.google.com/",
        website: "https://www.google.com/",
        city: "Oslo",
        alcohol_serving: true,
        open_hours: [{ label: "Mandag til fredag", value: "11:00 - 22:00" }],
        links: {
            online_booking: "https://www.google.com/",
        },
        gallery: [G1, G2, G3, G4],
        lat: 59.911491,
        lng: 10.757933,
    },
];
