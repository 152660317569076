import { Button, ButtonProps } from "@material-tailwind/react";
import { forwardRef } from "react";
 
interface IGPUIButton extends Omit<ButtonProps, "component"> {
    component?: any;
    to?: string;
}

const GPUIButton = forwardRef<HTMLButtonElement, IGPUIButton>((props, ref) => {
    const {children, ...restProps} = props
    return <Button {...restProps} ref={ref}>{children}</Button>;
});

GPUIButton.defaultProps = {
    variant: "filled",
};

export default GPUIButton;
